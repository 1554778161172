
import { Component, Vue, Watch } from 'vue-property-decorator'
import Chart, { ChartConfiguration, ChartDataset, ChartItem, LinearScaleOptions, ScaleOptions } from 'chart.js/auto'
import "chartjs-adapter-luxon";

function roundValue(val: number, decimals: number): string {
    return Number(Math.round(Number(val + 'e' + decimals)) + 'e-' + decimals).toFixed(decimals);
}

type DataType = {
    x: number, // A Date as a number of milliseconds sinds 1 Jan 1970
    y: number,      // AVG value
    yMin: number,   // Min value
    yMax: number    // Max value
}

type AxisType = "time" | "linear" | "logarithmic" | "category" | "timeseries" | "radialLinear"

type yAxisLabel = "y" | "yMin" | "yMax"

@Component({})
export default class DataGraph extends Vue {

    /** The HTML canvas element containing the chart. */
    canvas!: ChartItem

    /** The `Chart` object that contains all data concerning the displayed graph. */
    chart!: Chart

    /** Refers to the meterUnitId (dataset id) that is displayed
     * at each index in `chart.datasets`. */
    datasetIds: number[] = []

    datasetDictAvg: {[id: number]: ChartDataset} = {}
    datasetDictMin: {[id: number]: ChartDataset} = {}
    datasetDictMax: {[id: number]: ChartDataset} = {}

    /**
     * All yAxisIDs that are displayed through a dataset.
     * Each time a dataset is added, it adds the ID of the yAxis
     * it uses to this array.
     * Note: these IDs are without "y"-prefix.
     */
    yAxisIDsOnDisplay: number[] = []

    showMinValues = false
    showMaxValues = false
    

    datasetAlreadyOnDisplay(datasetId: number): boolean {
        return this.datasetIds.includes(datasetId)
    }

    yAxisWasCreated(unitSymbolAsNumber: number): boolean {
        return this.chart.options.scales !== undefined
            && ("y" + unitSymbolAsNumber) in this.chart.options.scales
    }

    yAxisAlreadyOnDisplay(unitSymbolAsNumber: number): boolean {
        return this.yAxisIDsOnDisplay.includes(unitSymbolAsNumber)
    }

    yAxisPositionShouldBeLeftOrRight(exceptAxis?: number): "left"|"right" {
        return this.numberOfUniqueYAxesOnDisplay(exceptAxis) % 2 == 0 ? "left" : "right"
    }

    uniqueYAxisIDsOnDisplay(): number[] {
        return [... new Set(this.yAxisIDsOnDisplay)]
    }

    numberOfUniqueYAxesOnDisplay(exceptAxis?: number): number {
        const uniqueAxes = this.uniqueYAxisIDsOnDisplay()
        if (exceptAxis === undefined) {
            return uniqueAxes.length
        } else {
            const exists = uniqueAxes.includes(exceptAxis)
            return uniqueAxes.length - (exists ? 1 : 0)
        }
    }

    /**
     * Adds a dataset to the array of datasets.
     * If a dataset with the same `id` already exists, it is replaced by the given dataset.
     * Also creates a new yAxis if necessary, or assigns an existing one.
     * @param meterUnitId Id of this dataset. Corresponds to a `MeterUnitId` and is unique.
     * @param data Sorted array of data to display.
     * @param label Label describing this dataset.
     * @param unitSymbolAsNumber ID of the y-axis. Corresponds to a `UnitId`. All data displaying data
     * of a certain unit should use the same `yAxisID`.
     * @param parsing Whether the dataset should be customly parsed. Defaults to `false`
     * to increase performance. If `false`, data should be ordered.
     * @param [graphColor="#7e7e7e"] Color of the graph.
     */
    addDataset(meterUnitId: number, data: DataType[], unitName: string,
        unitSymbolAsNumber: number, unitSymbol?: string,
        graphColor = "#7e7e7e"): void
    {
        // If a dataset with the same id already exists, only update its data.
        if (meterUnitId in this.datasetDictAvg) {
            this.datasetDictAvg[meterUnitId].data = data
            this.datasetDictMin[meterUnitId].data = data
            this.datasetDictMax[meterUnitId].data = data
        }
        // Otherwise, add a new dataset to the graph.
        else {
            // First, an axis should be configured.
            if (this.chart.options.scales && this.yAxisWasCreated(unitSymbolAsNumber)) {
                // If a corresponding axis already exists, it should be reconfigured
                // so that it is correctly placed on the left or right side of the chart.
                const newAxis = this.generateYAxis(unitSymbol, unitSymbolAsNumber)
                this.chart.options.scales["y" + unitSymbolAsNumber] = newAxis
            }
            else {
                // If not, an axis should be created.
                this.addYAxisIfNotExists(unitSymbolAsNumber, unitSymbol)
            }

            this.yAxisIDsOnDisplay.push(unitSymbolAsNumber)

            ;(["y", "yMin", "yMax"] as yAxisLabel[]).forEach((yArray: yAxisLabel) => {
                this.generateAndRegisterDataset(meterUnitId, unitName, yArray,
                    data, unitSymbolAsNumber, graphColor, unitSymbol)
            })
        }
        
        this.chart.update()
    }

    generateAndRegisterDataset(meterUnitId: number, unitName: string, yArray: yAxisLabel,
        data: DataType[], unitSymbolAsNumber: number,
        graphColor = "#7e7e7e", unitSymbol?: string): ChartDataset
    {
        this.datasetIds.push(meterUnitId)
        const dataset: ChartDataset = {
            label: unitName
                + (yArray == "yMin" ? " (MIN.)"
                    : yArray == "yMax" ? " (MAX.)" : "")
                + (unitSymbol ? ` [${unitSymbol}]` : ""),
            data: data,
            type: "line",
            xAxisID: "x",
            yAxisID: "y" + unitSymbolAsNumber,
            borderColor: graphColor,
            backgroundColor: graphColor,
            parsing: {
                yAxisKey: yArray
            },
            borderDash: yArray == "y" ? [] : [5, 5]
        }

        switch (yArray) {
            case "y":    this.datasetDictAvg[meterUnitId] = dataset; break
            case "yMin": this.datasetDictMin[meterUnitId] = dataset; break
            case "yMax": this.datasetDictMax[meterUnitId] = dataset
        }

        return dataset
    }

    @Watch("showMinValues")
    @Watch("showMaxValues")
    @Watch("datasetIds")
    updateDatasetsInChart(): void {
        this.chart.data.datasets = []

        Object.values(this.datasetDictAvg).forEach(avgData => {
            this.chart.data.datasets.push(avgData)
        })
        if (this.showMinValues) {
            Object.values(this.datasetDictMin).forEach(minData => {
                this.chart.data.datasets.push(minData)
            })
        }
        if (this.showMaxValues) {
            Object.values(this.datasetDictMax).forEach(maxData => {
                this.chart.data.datasets.push(maxData)
            })
        }

        this.chart.update()
    }

    /**
     * Removes a dataset with the given id.
     * If no other dataset uses the same axis, the used axis
     * is automatically hidden.
     * @param meterUnitId Id of the dataset.
     * @param unitSymbolAsNumber Unit symbol converted to a number. Used
     * as reference to yAxes.
     */
    removeDataset(meterUnitId: number, unitSymbolAsNumber: number): void {
        // Check whether a dataset with this id does exist.
        if (meterUnitId in this.datasetDictAvg) {
            const index = this.datasetIds.indexOf(meterUnitId)
            this.datasetIds.splice(index, 1)
            
            delete this.datasetDictAvg[meterUnitId]
            delete this.datasetDictMin[meterUnitId]
            delete this.datasetDictMax[meterUnitId]

            // Remove one occurrence of this dataset's axis id from
            // the list of displayed axes.
            const yAxisIDIndex = this.yAxisIDsOnDisplay.indexOf(unitSymbolAsNumber)
            if (yAxisIDIndex >= 0) {
                this.yAxisIDsOnDisplay.splice(yAxisIDIndex, 1)

                // If this axis used to draw grid lines on the chart,
                // then another axis should do it now, if any is visible.
                if (this.chart.options.scales) {
                    const yAxisID = "y" + unitSymbolAsNumber
                    const drewOnchartArea = this.chart.options.scales[yAxisID]?.grid?.drawOnChartArea
                    const numberOfYAxes = this.numberOfUniqueYAxesOnDisplay()
                    if (drewOnchartArea && numberOfYAxes > 0) {
                        const otherAxisID = "y" + this.uniqueYAxisIDsOnDisplay()[0]
                        this.chart.options.scales[otherAxisID]!.grid = {
                            drawOnChartArea: true
                        }

                        // Furthermore, if there is only one displayed axis
                        // left, it should be repositioned to the left.
                        if (numberOfYAxes == 1) {
                            (this.chart.options.scales[otherAxisID]! as LinearScaleOptions)
                                .position = "left"
                        }
                    }
                }
            }

            this.chart.update()
        }
    }

    generateYAxis(unitSymbol?: string, unitSymbolAsNumber?: number, type: AxisType = "linear"): ScaleOptions {
        return {
            title: {
                // Only show a title if `unitName` was given.
                text: unitSymbol ?? "",
                display: unitSymbol ? true : false,
            },
            type: type,
            display: "auto",
            // Place axis on the left or right depending on how many axes are being displayed.
            position: this.yAxisPositionShouldBeLeftOrRight(unitSymbolAsNumber),
            grid: {
                // Only display grid lines for this axis if no other axes are being displayed.
                drawOnChartArea: this.numberOfUniqueYAxesOnDisplay(unitSymbolAsNumber) == 0
            }
        }
    }

    /**
     * Adds a y-axis to the list of y-axes.
     * The axis ID is determined by the `UnitId` it represents, and formatted
     * as `"y" + UnitId` (e.g. `"y51"`).
     * This name is used for referencing the axis.
     * @param unitSymbolAsNumber Id of the `UnitId` that this axis represents.
     * @param type Scaling type of this axis. Defaults to `linear`.
     * @param position Axis position; either `right` or `left`. Defaults to `left`.
     * @param display Whether to display this axis or not. Defaults to `true`.
     * @param [axisColor="#7e7e7e"] Color of the axis.
     */
    addYAxisIfNotExists(unitSymbolAsNumber: number, unitName?: string,
        type: AxisType = "linear"): void
    {
        const yAxisID = "y" + unitSymbolAsNumber

        // If an axis with the same id already exists, no action is needed.
        if (this.chart.options.scales && yAxisID in this.chart.options.scales) return

        // Add a new axis if it did not exist yet.
        const newAxis: ScaleOptions = this.generateYAxis(unitName, undefined, type)

        this.chart.options.scales = {
            ...this.chart.options.scales,
            [yAxisID]: newAxis
        }


        this.chart.options.scales["x"] = this.xAxis

        this.chart.update()
    }


    downloadChart(): void {
        var link = document.createElement('a');
        link.download = 'datagrafiek.png';
        link.href = (document.getElementById('myChart') as HTMLCanvasElement).toDataURL()
        link.click();
    }

    /**
     * Generates the Chart object.
     */
    generateChart(): void {
        this.chart = new Chart(this.canvas, this.config())
        this.chart.render()
        this.chart.options.scales = {
            x: this.xAxis
        }
        this.chart.update()
    }

    reset(): void {
        this.chart.reset()
        this.generateChart()
    }

    get xAxis(): ScaleOptions {
        return {
            display: "auto",
            type: "time",
            time: {
                round: "minute",
                minUnit: "minute",
                // displayFormats: {
                //     minute: "hh:mm",
                //     hour: "D, hh:mm",
                //     day: "D",
                //     week: "D",
                //     month: "D",
                //     quarter: "D",
                //     year: "D"
                //     // hour: "D-M-Y H:00:00"
                //     // minute: "H:00"
                // },
                tooltipFormat: "D, hh:mm"
            },
            grid: {
                drawOnChartArea: false
            },
            ticks: {
                autoSkip: true,
                maxTicksLimit: 12,
                major: {
                    enabled: true
                }
            }
        }
    }

    config(): ChartConfiguration {
        return {
            type: "line",
            data: {
                datasets: []
            },
            options: {
                responsive: true,
                elements: {
                    point: {
                        radius: 2
                    },
                    line: {
                        tension: 0.33
                    }
                }
            }
        }
    }

    mounted() {
        this.canvas = document.getElementById("myChart") as ChartItem
        this.generateChart()
    }

    get userRole(): string {
        return this.$store.getters['identity/role']
    }

    get isModOrAdmin(): boolean {
        return this.userRole == 'Moderator' || this.userRole == 'Admin'
    }
}
