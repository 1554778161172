

import { NodeData, NodeInfo, CalcDataField, CalcDataFieldValue } from '@/types/state'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class NodeDataTable extends Vue {
    hardCodedOrder = [
        "alias", "Elektraverbruik", "Gasverbruik", "Elektra-teruglevering", "Elektraverbruik warmtepomp", "Warmteverbruik",
        "Warmwaterverbruik", "CV-ketelrendement", "Warmtepomprendement", "Systeemrendement", "Theoretische besparing", "CO2-reductie"
    ]

    @Prop()
    nodesWithData!: Array<NodeData>

    // data(): unknown {
    //     return {
    //         headers: [
    //             {
    //                 text: 'EMRA-box',
    //                 value: 'alias',
    //                 align: 'start'
    //             },
    //             { text: 'COP', value: 'cop' },
    //             { text: 'Elektraverbruik (kWh)', value: 'electricity-usage' },
    //             { text: 'Elektra-opwekking (kWh)', value: 'electricity-generation' },
    //             { text: 'Elektraverbruik warmtepomp (kWh)', value: 'electricity-usage-heat-pump' },
    //             { text: 'Warmwaterverbruik (m^3)', value: 'warm-water-usage' },
    //             { text: 'Gasverbruik (m^3)', value: 'gas-usage' },
    //             { text: 'Warmteverbruik (GJ)', value: 'warmth-usage' },
    //             { text: 'Theoretische besparing (€)', value: 'theoretical-savings' }
    //         ]
    //     }
    // }

    get headers(): unknown {
        const fields: Array<CalcDataField> = this.$store.getters['calcNodeData/CalcDataFields']?.data

        return fields == null ? null : [
            {
                text: 'EMRA-box',
                value: 'alias',
                align: 'start',
                unit: ''
            },
            ...fields.map((field: CalcDataField) => {
                let us = field.unitSymbol
                if (us == "J") us = "MJ"
                else if (us == "Wh") us = "kWh"
                else if (us == "m^3") us = "m³"
                else if (us == null) us = ""
                
                us = us != "" ? `[${us}]` : ""

                return {
                    text: field.fieldName,
                    value: field.fieldName,
                    unit: us,
                    align: 'right'
                }
            })
            .sort((a, b) => {
                const ia = this.hardCodedOrder.includes(a.text) ? this.hardCodedOrder.indexOf(a.text) : 0
                const ib = this.hardCodedOrder.includes(b.text) ? this.hardCodedOrder.indexOf(b.text) : 0
                return ia - ib
            })
        ]
    }

    get nodeDatas(): unknown {
        const selectedNodes: Array<NodeInfo> = this.$store.getters['default/ComparedNodes']
        if (selectedNodes == null || selectedNodes.length == 0) return null

        const selectedNodeAliases: Array<string> = selectedNodes.map((selectedNode: NodeInfo) => {
            return selectedNode.alias
        })

        return this.nodesWithData
            .filter((nodeData: NodeData) => selectedNodeAliases.includes(nodeData.alias))
            .map((nodeData: NodeData) => {
                const calcDataFieldValues: Array<CalcDataFieldValue> = nodeData.calcDataFieldValues

                const result: any = {
                    alias: nodeData.alias
                }

                calcDataFieldValues.forEach((cdfv: CalcDataFieldValue) => {
                    const fieldName = cdfv.calcDataFieldName
                    let resVal = ""
                    if (typeof cdfv.val === "number") {
                        if (fieldName == "Warmwaterverbruik") {
                            resVal = cdfv.val.toFixed(3)
                        } else if (fieldName == "Warmteverbruik") {
                            resVal = (cdfv.val / 1000000).toFixed(0)
                        } else if (fieldName == "Elektraverbruik warmtepomp") {
                            resVal = (cdfv.val / 1000).toFixed(0)
                        } else {
                            resVal = cdfv.val.toFixed(0)
                        }
                    }
                    result[fieldName.toString()] = resVal != "0" ? resVal : "-"
                })

                // Sort entries according to `hardCodedOrder` so that the data columns match the headers
                let resArr: any[] = []
                for (let key in result) {
                    if (Object.prototype.hasOwnProperty.call(result, key)) {
                        resArr.push({[key]: result[key]})
                    }
                }
                resArr = resArr.sort((a, b) => {
                    const ia = this.hardCodedOrder.includes(Object.keys(a)[0]) ? this.hardCodedOrder.indexOf(Object.keys(a)[0]) : 0
                    const ib = this.hardCodedOrder.includes(Object.keys(b)[0]) ? this.hardCodedOrder.indexOf(Object.keys(b)[0]) : 0
                    return ia - ib
                })
                .map(res => res[Object.keys(res)[0]])

                return resArr
            })
    }

    /**
     * Calculates the average value of a given property of all loaded nodes.
     * @param prop {keyof NodeData} Property to calculate the average of.
     * @returns Average value of the given property of all loaded nodes, or `undefined` if no values of the given property were found.
     */
    averageOfProperty(prop: keyof NodeData): number | undefined {
        let numberOfFoundProps = 0
        let sumOfFoundProps = 0

        for (let n = 0; n < this.nodesWithData.length; n++) {
            const node: NodeData = this.nodesWithData[n]
            const nodePropValue: any = node[prop]
            if (typeof nodePropValue === "string") return undefined
            if (nodePropValue !== undefined) {
                numberOfFoundProps++
                sumOfFoundProps += nodePropValue
            }
        }

        return numberOfFoundProps > 0 ? sumOfFoundProps / numberOfFoundProps : undefined
    }

    /**
     * Generates a string representation of a color to be used as the background of a data point based on its value compared to the average.
     * @param prop {string} Property that the data point represents
     * @param val {number} Number value of the data point
     * @param shouldbeBelowAvg {boolean} Whether the given value should be below the average (true) or above the average (false)
     *                         for it to be considered a good value
     * @returns {string} String representation of a color to be used as the background of a data point in a table.
     */
    getColor(prop: keyof NodeData, val: number, shouldBeBelowAvg: boolean): string {
        const propAvg = this.averageOfProperty(prop)
        if (propAvg === undefined) return 'red';
        
        if (shouldBeBelowAvg)
            return val <= propAvg ? '' : 'orange'
        else
            return val >= propAvg ? '' : 'orange';
    }

}

