
import { Component, Vue } from 'vue-property-decorator'

import NodeDataTable from '@/components/NodeDataTable.vue'
import { NodeData, NodeInfo } from '@/types/state'

// import { nodesWithData } from '@/dummyNodeData'

@Component({
    components: {
      NodeDataTable
    }
})
export default class DefaultNodeData extends Vue {
    startDateMenu = false
    endDateMenu = false

    startDate = new Date(Date.now() - 1000*60*60*24*7*26).toISOString().substr(0, 10)   // Half a year
    endDate = new Date(Date.now()).toISOString().substr(0, 10)  // Up to now

    formatDate(date: string): string {
        return date.substring(8, 10) + "/" + date.substring(5, 7) + "/" + date.substring(0, 4)
    }

    async retrieveData(): Promise<void> {
        this.$store.commit('calcNodeData/SET_PRELOADED', false)
        this.$store.commit('calcNodeData/SET_START_DATE', this.startDate)
        this.$store.commit('calcNodeData/SET_END_DATE', this.endDate)
        
        await this.$store.dispatch('calcNodeData/getNodeDatasInDateRange', [this.startDate, this.endDate])
            .then((response: any) => {
                const nodeDatas = response.data
                this.$store.commit('calcNodeData/SET_NODE_DATAS', nodeDatas)
            })
            .catch(() => {
                this.$store.commit('calcNodeData/SET_NODE_DATAS', [])
            })
    }

    // commitToStore(mutationString: string, newVal: any) {
    //     this.$store.commit(mutationString, newVal);
    // }

    get startDateText(): string {
        return this.formatDate(this.startDate)
    }

    get endDateText(): string {
        return this.formatDate(this.endDate)
    }

    get nodesWithData(): Array<NodeData> {
        // return nodesWithData
        return this.$store.getters['calcNodeData/NodeDatas']
    }

    get isLoaded(): boolean {
        // return true;
        return this.$store.getters['calcNodeData/IsPreloaded']
    }

    get comparisonNotEmpty(): boolean {
        const selectedNodes: Array<NodeInfo> = this.$store.getters['default/ComparedNodes']
        return selectedNodes != null && selectedNodes.length > 0
    }

    created(): void {
        this.$store.dispatch('calcNodeData/init')
    }
}

